import { cn } from "@/lib/utils";
import OtpInput from "react-otp-input";

interface IProps {
  className?: string;
  value?: string;
  onChange: (value: string) => void;
  numInputs?: number;
  disabled?: boolean;
}

export default function AuthCodeInput({
  value,
  onChange,
  numInputs = 6,
  disabled = false,
}: IProps) {
  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      containerStyle="space-x-4 justify-center"
      renderInput={(props, i) => {
        return (
          <div key={i}>
            <label htmlFor="code-1" className="sr-only">
              Input {i + 1}
            </label>
            <input
              {...props}
              disabled={disabled}
              inputMode="numeric"
              className={cn(
                props.className,
                "block w-[42px] h-[42px] py-3 text-lg text-center text-gray-900 bg-white border border-ribbon-stone-300",
                "rounded-lg focus:border-ribbon-cobalt-700 focus:outline-0 focus:ring-4 focus:ring-ribbon-cobalt-300",
                "focus:border-2"
              )}
              style={{}}
            />
          </div>
        );
      }}
    />
  );
}
