import { DatePicker } from "@/components/DatePicker";
import Header from "@/components/Header";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAppContext } from "@/contexts/appContext";
import { cn } from "@/lib/utils";
import { supabaseService } from "@/services/supabaseService";
import { ELegalSexOptions } from "@/types/common";
import { PRIMARY_USE_OPTIONS } from "@/utils/variables";
import { zodResolver } from "@hookform/resolvers/zod";
import { User } from "@supabase/supabase-js";
import { ChevronLeft, LogOut } from "lucide-react";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import * as zod from "zod";

enum FieldItems {
  Name = "name",
  Email = "email",
  DateOfBirth = "date-of-birth",
  LegalSex = "legal-sex",
  PrimaryUse = "primary-use",
}

interface IFieldItemProps {
  fieldItemId: FieldItems;
  label: string;
  isInEdit: boolean;
  disableEdit: boolean;
  render: () => ReactNode;
  renderEdit: () => ReactNode;
  onEdit: (field: FieldItems) => void;
  onCancel: (field: FieldItems) => void;
  onSave: (field: FieldItems) => void;
  className?: string;
  isLoading: boolean;
}

const userToFormData = (user: User) => {
  const userMetadata = user.user_metadata;
  return {
    first_name: userMetadata?.first_name || "",
    last_name: userMetadata?.last_name || "",
    email: userMetadata?.email || "",
    date_of_birth: userMetadata?.date_of_birth || "",
    legal_sex: userMetadata?.legal_sex || "",
    needs: userMetadata?.needs || [],
  };
};

const formSchema = zod.object({
  first_name: zod.string().min(1),
  last_name: zod.string().min(1),
  email: zod.string().email(),
  date_of_birth: zod.string().optional(),
  legal_sex: zod.string().optional(),
  needs: zod.string().array().optional(),
});

export default function ProfilePage() {
  const session: any = useLoaderData();
  const user: User = session.user;
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: userToFormData(user),
    resolver: zodResolver(formSchema),
  });
  const [fieldInEdit, setFieldInEdit] = useState<FieldItems | undefined>(
    undefined
  );
  const watchForm = form.watch();
  const fullName = `${watchForm?.first_name || ""} ${
    watchForm?.last_name || ""
  }`.trim();
  const supabase = supabaseService.getClient();
  const [isLoading, setIsLoading] = useState(false);
  const { logOut } = useAppContext();

  const handleCancelField = useCallback(() => {
    form.reset();
    setFieldInEdit(undefined);
  }, [form]);

  const onSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);
      const { data: responseData, error } = await supabase.auth.updateUser({
        data,
      });

      if (error) {
        toast.error(error?.message || "Error");
      } else {
        form.reset(userToFormData(responseData.user));
        setFieldInEdit(undefined);
        toast.success("Profile saved");
      }

      setIsLoading(false);
    },
    [form, supabase.auth]
  );

  const handleSaveField = useCallback(() => {
    form.handleSubmit(onSubmit)();
  }, [form, onSubmit]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="w-full max-w-screen-sm mx-auto px-5 mb-5">
      <Header>
        <div className="max-w-screen-sm mx-auto w-full px-6 py-5 grid grid-cols-header gap-4 items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={navigateBack}
            className="text-ribbon-cobalt-700"
          >
            <ChevronLeft size={30} />
          </Button>
          <div className="flex flex-col items-center justify-center">
            <div className="font-bold">Your Profile</div>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={logOut}
            className="text-ribbon-cobalt-700"
          >
            <LogOut size={26} />
          </Button>
        </div>
      </Header>
      <div>
        <Form {...form}>
          <section className="mb-3">
            <h2 className="font-bold text-2xl">Personal Info</h2>
            <FieldItem
              isLoading={isLoading}
              className="border-b border-gray-200"
              fieldItemId={FieldItems.Name}
              label="Name"
              render={() => <div className="text-xl">{fullName}</div>}
              renderEdit={() => (
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>First</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Last</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
              isInEdit={fieldInEdit === FieldItems.Name}
              disableEdit={!!fieldInEdit}
              onEdit={setFieldInEdit}
              onCancel={handleCancelField}
              onSave={handleSaveField}
            />
            <FieldItem
              isLoading={isLoading}
              className="border-b border-gray-200"
              fieldItemId={FieldItems.Email}
              label="Email"
              render={() => (
                <div>
                  {watchForm?.email || (
                    <span className="text-ribbon-stone-700">n/a</span>
                  )}
                </div>
              )}
              renderEdit={() => (
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input type="email" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
              isInEdit={fieldInEdit === FieldItems.Email}
              disableEdit={!!fieldInEdit}
              onEdit={setFieldInEdit}
              onCancel={handleCancelField}
              onSave={handleSaveField}
            />
            <FieldItem
              isLoading={isLoading}
              className="border-b border-gray-200"
              fieldItemId={FieldItems.DateOfBirth}
              label="Date of Birth"
              render={() => (
                <div>
                  {watchForm?.date_of_birth ? (
                    <div>
                      {DateTime.fromISO(watchForm.date_of_birth)
                        .setLocale("en-us")
                        .toFormat("DDD")}
                    </div>
                  ) : (
                    <span className="text-ribbon-stone-700">n/a</span>
                  )}
                </div>
              )}
              renderEdit={() => (
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="date_of_birth"
                    render={({ field }) => (
                      <FormItem>
                        <DatePicker
                          displayFormat={"PPP"}
                          date={
                            field.value
                              ? DateTime.fromISO(field.value).toJSDate()
                              : undefined
                          }
                          setDate={(date: Date | undefined) =>
                            field.onChange(
                              date ? DateTime.fromJSDate(date).toISODate() : ""
                            )
                          }
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
              isInEdit={fieldInEdit === FieldItems.DateOfBirth}
              disableEdit={!!fieldInEdit}
              onEdit={setFieldInEdit}
              onCancel={handleCancelField}
              onSave={handleSaveField}
            />
            <FieldItem
              isLoading={isLoading}
              fieldItemId={FieldItems.LegalSex}
              label="Legal Sex"
              render={() => (
                <div>
                  {watchForm?.legal_sex || (
                    <span className="text-ribbon-stone-700">n/a</span>
                  )}
                </div>
              )}
              renderEdit={() => (
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="legal_sex"
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value={ELegalSexOptions.Male}>
                              {ELegalSexOptions.Male}
                            </SelectItem>
                            <SelectItem value={ELegalSexOptions.Female}>
                              {ELegalSexOptions.Female}
                            </SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
              isInEdit={fieldInEdit === FieldItems.LegalSex}
              disableEdit={!!fieldInEdit}
              onEdit={setFieldInEdit}
              onCancel={handleCancelField}
              onSave={handleSaveField}
            />
          </section>
          <section>
            <h2 className="font-bold text-2xl mb-4">Primary Use</h2>
            <div className="space-y-2">
              {PRIMARY_USE_OPTIONS.map((item, index) => (
                <FormField
                  key={index}
                  control={form.control}
                  name="needs"
                  render={({ field }) => {
                    return (
                      <FormItem
                        key={index}
                        className="py-2 flex flex-row items-center justify-between space-x-3 space-y-0"
                      >
                        <FormLabel className="font-medium text-lg grow cursor-pointer">
                          {item}
                        </FormLabel>
                        <FormControl>
                          <Checkbox
                            checked={(field.value as any).includes(item)}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, item])
                                : field.onChange(
                                    field.value?.filter(
                                      (value: string) => value !== item
                                    )
                                  );
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              ))}
            </div>
            <Button
              className="w-[100px] mt-6"
              onClick={() => handleSaveField()}
              disabled={isLoading}
            >
              Save
            </Button>
          </section>
        </Form>
      </div>
    </div>
  );
}

function FieldItem({
  className,
  label,
  isInEdit,
  disableEdit,
  onEdit,
  onCancel,
  onSave,
  fieldItemId,
  render,
  renderEdit,
  isLoading,
}: IFieldItemProps) {
  return (
    <div className={cn("py-5", className)}>
      <div className="flex items-center justify-between mb-3">
        <FormLabel>{label}</FormLabel>
        {isInEdit ? (
          <Button
            variant="link"
            className="px-1 font-bold h-auto py-0"
            onClick={() => onCancel(fieldItemId)}
            disabled={isLoading}
          >
            Cancel
          </Button>
        ) : (
          <Button
            variant="link"
            className="px-1 font-bold h-auto py-0"
            onClick={() => onEdit(fieldItemId)}
            disabled={disableEdit}
          >
            Edit
          </Button>
        )}
      </div>
      <div className={cn(isInEdit && "hidden")}>{render()}</div>
      <div className={cn(!isInEdit && "hidden")}>{renderEdit()}</div>
      {isInEdit && (
        <Button
          className="w-[100px] mt-5"
          onClick={() => onSave(fieldItemId)}
          disabled={isLoading}
        >
          Save
        </Button>
      )}
    </div>
  );
}
