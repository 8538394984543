import { IIconProps } from "@/types/common";
import { COLORS } from "@/utils/colors";

export default function Ketone({
  className,
  color = COLORS.cobalt["700"],
}: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="19" cy="5" r="3" fill={color} />
      <path
        d="M19.2422 5L14.9995 9.24264"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 12L3 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 21L12 16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
