import { cn } from "@/lib/utils";
import { default as TimerIcon } from "@/components/icons/Timer";
import { formatTimerTime } from "@/utils/common";
import TimerString from "@/components/TimerString";

function Timer({
  time,
  className,
  hideIcon = false,
}: {
  time: number;
  className?: string;
  hideIcon?: boolean;
}) {
  const timeInSeconds = time / 1000;

  return (
    <div
      className={cn(
        "py-1 px-2 rounded-lg flex gap-1 items-center transition bg-ribbon-cobalt-300 text-xl min-w-24",
        "[&.timer-expire]:bg-ribbon-red [&.timer-expire_*]:text-white",
        timeInSeconds <= 10 ? "timer-expire" : undefined,
        className
      )}
    >
      {!hideIcon && <TimerIcon />}
      <TimerString
        timeString={formatTimerTime(timeInSeconds)}
        className="transition"
      />
    </div>
  );
}

export default Timer;
