import { ArrowRight } from "lucide-react";
import ResultStatusBadge from "./ResultStatusBadge";
import { Link } from "react-router-dom";

interface IProps {
  id: string;
  hasAbnormalResults: boolean;
  dateText: string;
}

export default function ResultsHistoryCard({
  id,
  hasAbnormalResults,
  dateText,
}: IProps) {
  return (
    <Link to={`/test-history/${id}`} key={id} className="block">
      <div className="bg-white shadow-lg rounded-2xl">
        <div className="flex items-center justify-between p-3 border-b border-gray-200">
          <div>
            <div className="text-ribbon-stone-700 mb-1">{dateText}</div>
            <h4 className="text-2xl font-bold">Ribbon Checkup</h4>
          </div>
          <div className="p-2 rounded-full text-ribbon-cobalt-700 bg-ribbon-stone-100">
            <ArrowRight size={26} />
          </div>
        </div>
        <div className="p-3 py-3 flex justify-start">
          <ResultStatusBadge isAbnormal={hasAbnormalResults} />
        </div>
      </div>
    </Link>
  );
}
