import { Predictions } from "@/types/common";
import { IInstructionsStep, ScanMediaType } from "@/types/Scan";
import PhotoOne from "@/assets/img/photo_one_urine_16x9.jpg";
import PhotoThree from "@/assets/img/photo_three_black_area.jpg";
import PhotoFour from "@/assets/img/pre-scan-image.jpg";
import ribbonLoop from "@/assets/mp4/Ribbon_Dip_Loop_Compressed.mp4";
import TakePhotoTimer from "@/pages/Scan/TakePhotoTimer";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { default as AlertIcon } from "@/components/icons/Alert";
import { formatTimerTime } from "./common";
import TimerString from "@/components/TimerString";

export const CONFIRM_PHOTO_STEP_ID = "confirm-photo";
export const ANALYZING_STRIP_STEP_ID = "analyzing-strip";
export const RESULT_STEP_ID = "result";
export const ERROR_STEP_ID = "error";
export const URINATE_STEP_ID = "urinate";
export const DIP_STEP_ID = "dip";
export const WAIT_FOR_DEVELOP_STEP_ID = "wait";
export const TAKE_PHOTO_STEP_ID = "take-photo";

export function reagentColorToRgbString(rgbArray: number[]) {
  return `rgb(${rgbArray.join(", ")})`;
}

export function isPredictionNormal(key: string, predictions: Predictions) {
  if (key === "uro") {
    return predictions.uro <= 1;
  } else if (key === "ph") {
    return predictions.ph <= 5;
  } else if (key === "sg") {
    return predictions.sg <= 5;
  } else {
    return predictions[key] === 0;
  }
}

export function checkHasAbnormalResults(predictions: Predictions) {
  let normalResultsCount = 0;
  Object.keys(predictions).forEach((key) => {
    const isNormal = isPredictionNormal(key, predictions);
    if (isNormal) normalResultsCount++;
  });

  const hasAbnormalResults =
    normalResultsCount < Object.keys(predictions).length;

  return hasAbnormalResults;
}

export const TEST_INSTRUCTIONS_STEPS: IInstructionsStep[] = [
  {
    id: URINATE_STEP_ID,
    title: "Urinate in the provided collection cup.",
    headerTitle: "Collect Sample",
    description: () =>
      "Try to fill the cup at least 3/4 of the way to ensure a good result.",
    btnLabel: "Sample Is Ready",
    media: {
      type: ScanMediaType.Image,
      src: PhotoOne,
    },
  },
  {
    id: DIP_STEP_ID,
    title: `Dip the test strip for 1-2 seconds`,
    headerTitle: "Dip Test Strip",
    description: () =>
      "Make sure all pads are wet. Then dry the edge before placing the strip in the black area of the Color Card.",
    btnLabel: "All Pads Are Wet",
    media: {
      type: ScanMediaType.Video,
      src: ribbonLoop,
    },
  },
  {
    id: WAIT_FOR_DEVELOP_STEP_ID,
    title: ({ timerTime }) => {
      const time =
        timerTime !== undefined
          ? ` ${formatTimerTime(timerTime / 1000)} `
          : " ";

      return (
        <>
          Wait{" "}
          <TimerString timeString={time} className="text-ribbon-stone-700" />{" "}
          for your test to develop, then click the button below to take a
          picture of your test and card
        </>
      );
    },
    headerTitle: "Develop Test",
    description: () => (
      <>
        <p className="mb-5">
          In the next step, you will have 1 minute to take a photo of your
          Color Card and test strip. Ribbon will then analyze the photo and
          deliver you your results.
        </p>
      </>
    ),
    btnLabel: "Take Photo",
    media: {
      type: ScanMediaType.Image,
      src: PhotoThree,
    },
    metadata: {
      startTimer: true,
      showTimer: true,
      isDevelopStrip: true,
    },
  },
  {
    id: TAKE_PHOTO_STEP_ID,
    title: "Take your photo now",
    headerTitle: "Take Photo",
    description: ({ timerTime }) => {
      return (
        <>
          {timerTime !== undefined && (
            <TakePhotoTimer time={timerTime} className="mb-5" />
          )}
          <Alert variant="warning" className="[&>svg]:top-4">
            <AlertIcon />
            <AlertDescription>
              Make sure there is space around the Color Card when you take your
              photo.
            </AlertDescription>
          </Alert>
        </>
      );
    },
    btnLabel: "Take Photo",
    media: {
      type: ScanMediaType.Image,
      src: PhotoFour,
    },
    metadata: {
      takePhotoStep: true,
    },
  },
];
