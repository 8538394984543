import Header from "@/components/Header";
import { useCallback, useMemo, useState } from "react";
import {
  CreatePassword,
  ForgotPassword,
  Password,
  ResetPassword,
  PersonalInfo,
  PhoneNumber,
  PhoneVerify,
  PrimaryUse,
} from "./Steps";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { supabaseService } from "@/services/supabaseService";
import { sanitizePhoneNumber } from "@/utils/common";
import { toast } from "sonner";
import { EAuthStep } from "@/types/common";
import { cn } from "@/lib/utils";
import { IAuthLocationState } from "@/types/auth";

const STEPS = [
  { id: EAuthStep.PhoneNumber, Component: PhoneNumber },
  { id: EAuthStep.PhoneVerify, Component: PhoneVerify },
  { id: EAuthStep.Password, Component: Password },
  { id: EAuthStep.ForgotPassword, Component: ForgotPassword },
  { id: EAuthStep.ResetPassword, Component: ResetPassword },
  { id: EAuthStep.CreatePassword, Component: CreatePassword },
  { id: EAuthStep.PersonalInfo, Component: PersonalInfo },
  { id: EAuthStep.PrimaryUse, Component: PrimaryUse },
];

function Auth() {
  const { stepId } = useParams();
  const [stepsFormData, setStepsFormData] = useState<any>({});
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const supabase = supabaseService.getClient();
  const CurrentStep = STEPS.find((s) => s.id === stepId);
  const locationState: IAuthLocationState = useMemo(
    () => location.state || {},
    [location.state]
  );

  const signupSteps = useMemo(() => {
    const baseSteps = [EAuthStep.PersonalInfo, EAuthStep.PrimaryUse];

    if (locationState.usePasswordAuth) {
      return [EAuthStep.CreatePassword, ...baseSteps];
    } else {
      return baseSteps;
    }
  }, [locationState.usePasswordAuth]);

  const navigateAuth = useCallback(
    (path: string, state = {}, replace = false) => {
      const currentState = locationState;
      navigate(`/auth/${path}`, { state: { ...currentState, ...state } });
    },
    [locationState, navigate]
  );

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handlePhoneNumberStep = useCallback(
    async (formData: any) => {
      const phone = sanitizePhoneNumber(formData.phoneNumber);
      const authInfoResponse = await supabaseService.getAuthInfo(phone);
      const { data: authInfo, error: authInfoError } = authInfoResponse;
      if (authInfoError) throw authInfoError;
      const fallbackAuthInfoResponse =
        await supabaseService.getFallbackAuthInfo(phone);
      const { data: fallbackAuthInfo, error: fallbackAuthInfoError } =
        fallbackAuthInfoResponse;
      if (fallbackAuthInfoError) throw authInfoError;

      const nextRouteState: IAuthLocationState = {
        [EAuthStep.PhoneNumber]: formData,
        phoneNumber: formData.phoneNumber,
        authInfo,
        fallbackAuthInfo,
      };

      if (fallbackAuthInfo.is_registered) {
        navigateAuth(EAuthStep.Password, nextRouteState);
      } else if (authInfo.is_registered) {
        const { error } = await supabase.auth.signInWithOtp({
          phone,
        });
        if (error) throw error;
        navigateAuth(EAuthStep.PhoneVerify, nextRouteState);
      } else {
        const { error } = await supabase.auth.signInWithOtp({
          phone,
        });

        if (error) {
          navigateAuth(EAuthStep.CreatePassword, {
            ...nextRouteState,
            useFallbackAuth: true,
          });
        } else {
          navigateAuth(EAuthStep.PhoneVerify, nextRouteState);
        }
      }
    },
    [navigateAuth, supabase]
  );

  const handleForgotPasswordStep = useCallback(
    async (formData: any) => {
      const { email } = formData;
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/auth/reset-password`,
      });
      if (error) throw error;
      toast.info("Check your inbox!", {
        description: `A reset password link has been sent to ${email}`,
      });
      navigate("/");
    },
    [navigate, supabase.auth]
  );

  const handleVerifyPhoneNumberStep = useCallback(
    async (formData: any) => {
      const { otp, phone } = formData;
      const { error } = await supabase.auth.verifyOtp({
        phone: sanitizePhoneNumber(phone),
        token: otp,
        type: "sms",
      });
      if (error) throw error;

      if (location.state?.authInfo.is_registered) {
        toast.success("Log in successfull");
        navigate("/");
      } else {
        navigateAuth(EAuthStep.PersonalInfo);
      }
    },
    [location.state, navigate, navigateAuth, supabase.auth]
  );

  const handleResetPasswordStep = useCallback(
    async (formData: any) => {
      const { password } = formData;
      const { error } = await supabase.auth.updateUser({
        password,
      });
      if (error) {
        throw error;
      } else {
        toast.success("Password reset successfull");
        navigate("/");
      }
    },
    [navigate, supabase.auth]
  );

  const handlePasswordStep = useCallback(
    async (formData: any) => {
      const { password } = formData;
      const email = locationState.fallbackAuthInfo?.email;

      const { error } = await supabase.auth.signInWithPassword({
        email: email || "",
        password: password,
      });
      if (error) throw error;

      toast.success("Log in successfull");
      navigate("/");
    },
    [locationState, navigate, supabase.auth]
  );

  const handleCreatePasswordStep = useCallback(
    async (formData: any) => {
      navigateAuth(EAuthStep.PersonalInfo, {
        [EAuthStep.CreatePassword]: formData,
      });
    },
    [navigateAuth]
  );

  const handlePersonalInfoStep = useCallback(
    async (formData: any) => {
      navigateAuth(EAuthStep.PrimaryUse, {
        [EAuthStep.PersonalInfo]: formData,
      });
    },
    [navigateAuth]
  );

  const handlePrimaryUseStep = useCallback(
    async (formData: any) => {
      const { needs } = formData;
      const personalInfoFormData = locationState[EAuthStep.PersonalInfo];
      const { firstName, lastName, email } = personalInfoFormData;
      const phoneNumber = sanitizePhoneNumber(locationState.phoneNumber || "");
      const useFallbackAuth = locationState.useFallbackAuth || false;
      const userMetadata = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        needs,
      };

      if (useFallbackAuth) {
        const password = locationState[EAuthStep.CreatePassword]?.password;
        const { error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: { ...userMetadata, fallback_auth_phone_number: phoneNumber },
            emailRedirectTo: window.location.origin,
          },
        });
        if (error) throw error;
        toast.success(
          "Account created! Please, check your inbox to verify the email address"
        );
      } else {
        const { error } = await supabase.auth.updateUser({
          data: userMetadata,
        });
        if (error) throw error;
        toast.success("Account created!");
      }
      navigate("/");
    },
    [locationState, navigate, supabase.auth]
  );

  const createSubmitHandler = useCallback(
    (stepId: EAuthStep) => {
      return async (data: any) => {
        setStepsFormData((current: any) => ({ ...current, [stepId]: data }));
        setIsLoading(true);

        try {
          switch (stepId) {
            case EAuthStep.PhoneNumber:
              await handlePhoneNumberStep(data);
              break;
            case EAuthStep.PhoneVerify:
              await handleVerifyPhoneNumberStep(data);
              break;
            case EAuthStep.ForgotPassword:
              await handleForgotPasswordStep(data);
              break;
            case EAuthStep.ResetPassword:
              await handleResetPasswordStep(data);
              break;
            case EAuthStep.Password:
              await handlePasswordStep(data);
              break;
            case EAuthStep.CreatePassword:
              await handleCreatePasswordStep(data);
              break;
            case EAuthStep.PersonalInfo:
              await handlePersonalInfoStep(data);
              break;
            case EAuthStep.PrimaryUse:
              await handlePrimaryUseStep(data);
              break;
            default:
              break;
          }
        } catch (error: any) {
          toast.error(error.message || "Error");
        } finally {
          setIsLoading(false);
        }
      };
    },
    [
      handlePersonalInfoStep,
      handlePhoneNumberStep,
      handlePrimaryUseStep,
      handleVerifyPhoneNumberStep,
      handleCreatePasswordStep,
      handlePasswordStep,
      handleForgotPasswordStep,
      handleResetPasswordStep,
    ]
  );

  switch (true) {
    case !stepId: // step id is not defined
    case !!stepId && !CurrentStep: // step id is defined but there's no corresponding step
    case stepId !== STEPS[0].id &&
      stepId !== EAuthStep.ResetPassword &&
      !location.state?.phoneNumber: // prevent direct url access to any of the auth step
      return <Navigate to={`/auth/${STEPS[0].id}`} replace />;
    default:
      break;
  }

  return (
    <>
      <Header>
        <div className="mx-auto max-w-screen-sm px-6 py-5 grid grid-cols-header gap-4 items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={navigateBack}
            className="text-ribbon-cobalt-700"
          >
            <ChevronLeft size={30} />
          </Button>
          <div className="flex flex-col items-center justify-center"></div>
          <div />
        </div>
      </Header>
      <div className="w-full max-w-screen-sm px-5 pb-5 mx-auto">
        <SignupProgressBar signupSteps={signupSteps} />
        {CurrentStep && (
          <CurrentStep.Component
            onSubmit={createSubmitHandler(CurrentStep.id)}
            isLoading={isLoading}
            stepsFormData={stepsFormData}
          />
        )}
      </div>
    </>
  );
}

function SignupProgressBar({ signupSteps }: { signupSteps: EAuthStep[] }) {
  const { stepId } = useParams();
  const signupStepIndex = signupSteps.findIndex((i) => i === stepId);

  const fragments = useMemo(() => {
    const fragments = [];
    for (let i = 0; i < signupSteps.length; i++) {
      fragments.push(
        <div
          key={i}
          className={cn(
            "bg-ribbon-stone-300 h-2 rounded flex-1",
            i <= signupStepIndex && "active",
            "[&.active]:bg-ribbon-stone-900 transition"
          )}
        ></div>
      );
    }
    return fragments;
  }, [signupStepIndex, signupSteps]);

  if (signupStepIndex >= 0) {
    return <div className="flex gap-2">{fragments}</div>;
  }

  return null;
}

export default Auth;
