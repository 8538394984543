import { useCallback, useEffect, useState } from "react";

export enum TimerStatus {
  Running = 1,
  Paused = 2,
  Stopped = 3,
  Expired = 4,
}

interface IProps {
  time?: number;
}

const TIME_STEP = 1000;

export default function useTimer({ time: initialTime = 0 }: IProps) {
  const [state, setState] = useState<{
    time: number;
    status: TimerStatus;
    timerStartedAt: number | undefined;
  }>({
    time: initialTime * 1000,
    status: TimerStatus.Stopped,
    timerStartedAt: undefined,
  });

  const start = useCallback(() => {
    setState((state) => ({
      ...state,
      status: TimerStatus.Running,
      timerStartedAt: Date.now(),
    }));
  }, []);

  const reset = useCallback(() => {
    setState((state) => ({
      ...state,
      time: initialTime * 1000,
      status: TimerStatus.Stopped,
      timerStartedAt: undefined,
    }));
  }, [initialTime]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (state.status === TimerStatus.Running) {
      intervalId = setInterval(() => {
        setState((state) => {
          let nextTime = state.time - TIME_STEP;
          if (nextTime < 0) {
            return {
              ...state,
              status: TimerStatus.Expired,
              time: 0,
            };
          } else {
            return {
              ...state,
              time: nextTime,
            };
          }
        });
      }, TIME_STEP);
    } else if (intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [state.status]);

  useEffect(() => {
    if (state.timerStartedAt && state.time) {
      const now = Date.now();
      if ((now - state.timerStartedAt) / 1000 > initialTime) {
        setState((state) => ({ ...state, status: TimerStatus.Expired }));
      }
    }
  }, [initialTime, state.timerStartedAt, state.time]);

  return {
    start,
    reset,
    time: state.time,
    status: state.status,
  };
}
