import ButtonsContainer from "@/components/ButtonsContainer";
import Header from "@/components/Header";
import OverrideTheme from "@/components/OverrideTheme";
import { Button } from "@/components/ui/button";
import { Player } from "@lottiefiles/react-lottie-player";
import { ChevronLeft } from "lucide-react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import lottieTwo from "@/assets/lotties/2lottie.json";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCurrentUserLegalSex } from "@/utils/common";
import PackagePhoto from "@/assets/img/photo_checkup_package.png";

const checklistItems = [
  {
    id: "check-package",
    content: (
      <>
        Make sure that the test strip package is sealed, and remains closed when
        not in use. If the test strip packaging is left open the tests will be
        damaged.
      </>
    ),
  },
  {
    id: "menstruation-warning",
    content: (
      <>
        If you are taking this test while menstruating, some results may be
        compromised. Consider testing at another time for reliable results.
      </>
    ),
  },
];

const steps = [
  {
    id: "the-ribbon-checkup",
    headerTitle: "About This Test",
    renderContent: () => (
      <>
        <Player src={lottieTwo} loop autoplay className="max-w-96" />
        <div className="my-4">
          <h2 className="mb-4 text-2.5xl font-medium">The Ribbon Checkup</h2>
          <p className="mb-5">
            Your urine holds vital information about your health. The Ribbon
            Checkup provides you with valuable insights between doctor visits so
            medical professionals can look further into the issue.
          </p>
          <p className="text-sm">
            *Ribbon still recommends visiting your doctor for annual checkups.
            This product is not intended for use in diagnosing diseases or other
            conditions; determining or monitoring the state of health; or
            curing, mitigating, treating or preventing and diseases.
          </p>
        </div>
      </>
    ),
    renderButtons: (steps: Array<{ id: string }>) => (
      <>
        <Button
          asChild
          variant="outline"
          className="grow hover:text-primary-foreground"
        >
          <Link to={"/tutorial/" + steps[1].id}>Continue</Link>
        </Button>
      </>
    ),
  },
  {
    id: "checklist",
    headerTitle: "Checklist",
    renderContent: () => (
      <>
        <img
          src={PackagePhoto}
          alt="Strip Package"
          className="w-full rounded-2xl"
        />
        <div className="my-4">
          <h2 className="mb-4 text-2.5xl font-medium">Before you begin</h2>
          <Checklist />
        </div>
      </>
    ),
    renderButtons: (steps: Array<{ id: string }>) => (
      <>
        <Button asChild className="grow">
          <Link to="/test">Start Test</Link>
        </Button>
      </>
    ),
  },
];

export default function Tutorial() {
  const { stepId } = useParams();
  const navigate = useNavigate();

  const step = useMemo(() => {
    return steps.find((step) => step.id === stepId);
  }, [stepId]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // const cancel = useCallback(() => {
  //   navigate("/", { replace: true });
  // }, [navigate]);

  if (!stepId) return <Navigate to={`/tutorial/${steps[0].id}`} replace />;

  return (
    <div className="px-6 grow flex flex-col w-full max-w-screen-sm mx-auto">
      <Header>
        <div className="mx-auto max-w-screen-sm px-6 py-5 grid grid-cols-header gap-4 items-center">
          <Button variant="ghost" size="icon" onClick={navigateBack}>
            <ChevronLeft size={30} />
          </Button>
          <div className="px-4 text-center font-bold">
            {step && step.headerTitle}
          </div>
          {/*<Button variant="ghost" size="icon" onClick={cancel}>
            <X size={30} />
          </Button>*/}
        </div>
      </Header>
      {step && step.renderContent()}
      <ButtonsContainer>{step && step.renderButtons(steps)}</ButtonsContainer>
      <OverrideTheme
        background="224 99% 17%"
        foreground="0 0% 100%"
        primary="200 99% 90%"
        primaryForeground="224 97% 30%"
      />
    </div>
  );
}

function Checklist() {
  const [legalSex, setLegalSex] = useState<any>("Male");

  const fetchLegalSex = useCallback(async () => {
    const legalSex = await getCurrentUserLegalSex();
    setLegalSex(legalSex);
  }, []);

  useEffect(() => {
    fetchLegalSex();
  }, [fetchLegalSex]);

  return (
    <ul className="space-y-4">
      {checklistItems
        .filter((item) => {
          if (legalSex === "Male" && item.id === "menstruation-warning") {
            return false;
          } else {
            return true;
          }
        })
        .map((item, index) => {
          return (
            <li key={item.id} className="grid grid-cols-[32px_1fr] gap-5">
              <div>
                <div className="leading-[32px] bg-ribbon-cobalt-700 rounded-full text-center text-[20px]">
                  {index + 1}
                </div>
              </div>
              <div>{item.content}</div>
            </li>
          );
        })}
    </ul>
  );
}
