/* eslint-disable jsx-a11y/anchor-is-valid */
import AuthCodeInput from "@/components/AuthCodeInput";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { supabaseService } from "@/services/supabaseService";
import { IAuthLocationState } from "@/types/auth";
import { EAuthStep, IAuthStepProps } from "@/types/common";
import { sanitizePhoneNumber } from "@/utils/common";
import { PRIMARY_USE_OPTIONS } from "@/utils/variables";
import { zodResolver } from "@hookform/resolvers/zod";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ArrowRight } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "sonner";
import { withMask } from "use-mask-input";
import * as zod from "zod";

const phoneNumberSchema = zod
  .string()
  .min(1)
  .refine((value) => {
    const result = isValidPhoneNumber(value);
    return result;
  });

const passwordSchema = zod
  .string()
  .min(8, "Password must contain at least 8 characters")
  .refine((val) => /[0-9@!#]/.test(val), {
    message: "Password must include a number or symbol",
  });

const personalInfoSchema = zod
  .object({
    firstName: zod.string().min(1),
    lastName: zod.string().min(1),
    email: zod.string().email().optional(),
    useFallbackAuth: zod.boolean(),
  })
  .superRefine((values, ctx) => {
    if (values.useFallbackAuth === true && !values.email) {
      ctx.addIssue({
        message: "Email is required",
        code: zod.ZodIssueCode.custom,
        path: ["email"],
      });
    }
  });

const phoneNumberFormSchema = zod.object({
  phoneNumber: phoneNumberSchema,
});

const primaryUseOptions = PRIMARY_USE_OPTIONS;

export function PhoneNumber({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    resolver: zodResolver(phoneNumberFormSchema),
    defaultValues: { phoneNumber: "" },
  });

  return (
    <div className="pt-[15vh]">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-9">
          Let’s start with your phone number
        </h2>
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="mb-7">
              <FormLabel>Phone number</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  ref={withMask("+1 (999) 999-9999")}
                  placeholder="+1 (123) 456-7890"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full space-x-2"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <span>Continue</span>
          <ArrowRight />
        </Button>
      </Form>
    </div>
  );
}

export function Password({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    defaultValues: { password: "" },
  });
  const location = useLocation();

  return (
    <div className="pt-[15vh]">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-3">Welcome back!</h2>
        <p className="text-center mb-6 text-ribbon-stone-700">
          Enter your password to complete logging in.
        </p>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="mb-7">
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full space-x-2 mb-4"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <span>Log In</span>
          <ArrowRight />
        </Button>
        <Button asChild variant="link" className=" w-full font-bold">
          <Link to={`/auth/${EAuthStep.ForgotPassword}`} state={location.state}>
            Forgot Password?
          </Link>
        </Button>
      </Form>
    </div>
  );
}

export function ForgotPassword({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    resolver: zodResolver(
      zod.object({
        email: zod.string().email(),
      })
    ),
    defaultValues: {
      email: "",
    },
  });
  const location = useLocation();
  const locationState: IAuthLocationState = useMemo(
    () => location.state || {},
    [location.state]
  );

  useEffect(() => {
    form.setValue("email", locationState.fallbackAuthInfo?.email || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <div className="pt-[15vh]">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-3">
          Forgot your password?
        </h2>
        <p className="text-center mb-6 text-ribbon-stone-700">
          Enter the email address associated with your account.
        </p>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="mb-7">
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full space-x-2"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <span>Continue</span>
          <ArrowRight />
        </Button>
      </Form>
    </div>
  );
}

export function PhoneVerify({ onSubmit, isLoading }: IAuthStepProps) {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const supabase = supabaseService.getClient();

  const handleResendCode = useCallback(async () => {
    const phoneNumber = location.state?.phoneNumber;
    if (phoneNumber) {
      setOtp("");
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({
        phone: sanitizePhoneNumber(phoneNumber),
      });
      if (error) {
        toast.error(error.message || "Error");
      } else {
        toast.success("Code has been sent");
      }
      setLoading(false);
    }
  }, [location.state, supabase.auth]);

  const handleChangeOtp = useCallback(
    (value: string) => {
      if (isLoading) return;
      setOtp(value);
      if (value.length === 6) {
        onSubmit({ otp: value, phone: location.state?.phoneNumber });
      }
    },
    [isLoading, location.state?.phoneNumber, onSubmit]
  );

  if (!location.state?.phoneNumber) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <div className="pt-[15vh]">
      <h2 className="text-2xl font-bold text-center mb-3">
        Verify your phone number
      </h2>
      {location.state.phoneNumber && (
        <p className="text-center mb-6 text-ribbon-stone-700">
          Enter the code sent to {location.state.phoneNumber}.
        </p>
      )}
      <div className="mb-6">
        <AuthCodeInput
          value={otp}
          onChange={handleChangeOtp}
          disabled={isLoading}
        />
        <div className="my-6 text-center text-gray-500 px-4">
          Didn’t get a code?{" "}
          <Button
            disabled={loading}
            variant="link"
            className="p-0 h-auto font-bold"
            onClick={handleResendCode}
          >
            Send again
          </Button>
        </div>
      </div>
    </div>
  );
}

export function CreatePassword({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    defaultValues: { password: "" },
    resolver: zodResolver(zod.object({ password: passwordSchema })),
  });

  return (
    <div className="pt-8">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-6">
          Create a password
        </h2>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="mb-7">
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <p className="mb-6 text-ribbon-stone-700">
          By selecting ‘Agree and Continue’, I certify that I am 18 years of age
          or older, agree to the{" "}
          <Button asChild variant="link" className="p-0 h-auto font-bold">
            <a href="#">User Agreement</a>
          </Button>
          , and acknowledge{" "}
          <Button asChild variant="link" className="p-0 h-auto font-bold">
            <a href="#">Privacy Policy</a>
          </Button>
          .
        </p>
        <Button
          className="w-full space-x-2 mb-4"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <span>Agree and Continue</span>
          <ArrowRight />
        </Button>
      </Form>
    </div>
  );
}

export function ResetPassword({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    defaultValues: { password: "", confirmPassword: "" },
    resolver: zodResolver(
      zod
        .object({
          password: passwordSchema,
          confirmPassword: passwordSchema,
        })
        .superRefine(({ confirmPassword, password }, ctx) => {
          if (confirmPassword !== password) {
            ctx.addIssue({
              code: "custom",
              message: "Passwords don't not match",
              path: ["confirmPassword"],
            });
          }
        })
    ),
  });

  return (
    <div className="pt-[15vh]">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-3">
          Enter your new password
        </h2>
        <p className="text-center mb-6 text-ribbon-stone-700">
          Must be at least 8 characters long and contain a number or symbol.
        </p>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="mb-3">
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <ul className="mb-6 text-ribbon-stone-700 list-disc pl-4">
          <li>8 characters long</li>
          <li>Includes a number or symbol</li>
        </ul>
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem className="mb-9">
              <FormLabel>Confirm Password</FormLabel>
              <FormControl>
                <Input {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full space-x-2 mb-4"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <span>Update Password</span>
        </Button>
      </Form>
    </div>
  );
}

export function PersonalInfo({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      useFallbackAuth: false,
    },
    resolver: zodResolver(personalInfoSchema),
  });
  const location = useLocation();
  const locationState: IAuthLocationState = useMemo(
    () => location.state || {},
    [location.state]
  );

  useEffect(() => {
    form.setValue("useFallbackAuth", locationState.useFallbackAuth === true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState.useFallbackAuth]);

  return (
    <div className="pt-8">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-8">Personal info</h2>
        <div className="space-y-6">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Jane" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Smith" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel>
                  Email{" "}
                  <span className="font-normal text-ribbon-stone-700">
                    (Optional)
                  </span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="name@company.com" />
                </FormControl>
                <FormMessage />
                <FormDescription className="text-ribbon-stone-700">
                  Receive updates and promotions.
                </FormDescription>
              </FormItem>
            )}
          />
          <Button
            className="w-full space-x-2 mb-4"
            onClick={form.handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            <span>Finish</span>
            <ArrowRight />
          </Button>
        </div>
      </Form>
    </div>
  );
}

export function PrimaryUse({ onSubmit, isLoading }: IAuthStepProps) {
  const form = useForm({
    defaultValues: {
      needs: [],
    },
    resolver: zodResolver(
      zod.object({ needs: zod.string().array().optional() })
    ),
  });

  return (
    <div className="pt-8">
      <Form {...form}>
        <h2 className="text-2xl font-bold text-center mb-3">
          What will you primarily use Ribbon for?
        </h2>
        <p className="text-center mb-6 text-ribbon-stone-700">
          This helps us send you relevant information following testing. You can
          update these later.
        </p>
        <div className="space-y-2 mb-8">
          {primaryUseOptions.map((item, index) => (
            <FormField
              key={index}
              control={form.control}
              name="needs"
              render={({ field }) => {
                return (
                  <FormItem
                    key={index}
                    className="py-2 flex flex-row items-center justify-between space-x-3 space-y-0"
                  >
                    <FormLabel className="font-medium text-lg grow cursor-pointer">
                      {item}
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={(field.value as any).includes(item)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item])
                            : field.onChange(
                                field.value?.filter(
                                  (value: string) => value !== item
                                )
                              );
                        }}
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
        <div className="space-y-3">
          <Button
            className="w-full space-x-2"
            onClick={form.handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            <span>Save</span>
          </Button>
          <Button
            variant="outline"
            className="w-full space-x-2"
            onClick={() => onSubmit({ needs: [] })}
            disabled={isLoading}
          >
            <span>Skip</span>
          </Button>
        </div>
      </Form>
    </div>
  );
}
