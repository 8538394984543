import HomeImage1 from "@/assets/img/home5.jpg";
import HomeImage2 from "@/assets/img/home4.jpg";
import HomeImage3 from "@/assets/img/home1.jpg";
import HomeImage4 from "@/assets/img/home6.jpg";
import useMeasure from "react-use-measure";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useAppContext } from "@/contexts/appContext";
import { useMemo } from "react";
import AppLogo2 from "@/components/AppLogo/AppLogo2";

const colorsRow1 = ["#90C7D0", "#8F8BA9", "#CAEDFF"];
const colorsRow2 = ["#7198C8", "#D7D7F3", "#EDEBE4"];
const sequenceRow1 = [
  `url(${HomeImage1})`,
  colorsRow1[0],
  `url(${HomeImage2})`,
  colorsRow1[1],
];
const sequenceRow2 = [
  colorsRow2[0],
  `url(${HomeImage3})`,
  colorsRow2[1],
  `url(${HomeImage4})`,
];
const squareSize = 100;
const gap = 10;
const offsetRow1 = squareSize * 0.2;
const offsetRow2 = squareSize * 0.6;

export default function GuestHomePage() {
  const { isLoggedIn } = useAppContext();
  const [squaresGridContainerRef, squaresGridContainerBounds] = useMeasure();

  const { componentsRow1, componentsRow2 } = useMemo(() => {
    const containerWidth = squaresGridContainerBounds.width;
    const row1 = [...sequenceRow1];
    const row2 = [...sequenceRow2];

    let i = 0;
    while (
      row1.length * (squareSize + gap) - gap <
      containerWidth + squareSize + gap
    ) {
      const key = i % 3;
      const color1 = colorsRow1[key];
      const color2 = colorsRow2[key];

      row1.push(color1);
      row2.push(color2);
      i++;
    }

    const createComponent = (item: string, index: number) => (
      <div
        key={index}
        style={{
          width: squareSize,
          height: squareSize,
          background: `${item}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="rounded-xl grow-0 shrink-0"
      />
    );

    const componentsRow1 = row1.map(createComponent);
    const componentsRow2 = row2.map(createComponent);

    return {
      componentsRow1,
      componentsRow2,
    };
  }, [squaresGridContainerBounds.width]);

  return (
    <>
      <div>
        <div
          className="w-full max-w-full overflow-hidden py-8 flex flex-col"
          style={{ gap }}
          ref={squaresGridContainerRef}
        >
          <div
            className="flex flex-row flex-nowrap relative"
            style={{ gap, left: offsetRow1 * -1 }}
          >
            {componentsRow1}
          </div>
          <div
            className="flex flex-row flex-nowrap relative"
            style={{ gap, left: offsetRow2 * -1 }}
          >
            {componentsRow2}
          </div>
        </div>
      </div>
      <div className="grow flex flex-col align-stretch justify-center p-8">
        <div className="mx-auto mb-6">
          <AppLogo2 />
        </div>
        <div className="text-center text-ribbon-stone-700 max-w-[274px] mx-auto">
          Easy & accurate diagnostic testing – all from the comfort of your
          home.
        </div>
      </div>
      <div className="space-y-4 flex flex-col p-8 max-w-screen-sm mx-auto w-full">
        <Button asChild>
          <Link to="/tutorial">Begin Testing</Link>
        </Button>
        {!isLoggedIn && (
          <Button asChild variant="outline">
            <Link to="/auth">Log In or Sign Up</Link>
          </Button>
        )}
      </div>
    </>
  );
}
