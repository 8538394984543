import Header from "@/components/Header";
import OverrideTheme from "@/components/OverrideTheme";
import ResultsHistoryCard from "@/components/ResultsHistoryCard";
import Spinner from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { useAppContext } from "@/contexts/appContext";
import { supabaseService } from "@/services/supabaseService";
import { checkHasAbnormalResults } from "@/utils/scanUtils";
import { QUERY_KEY } from "@/utils/variables";
import { useQuery } from "@tanstack/react-query";
import { ChevronLeft, House } from "lucide-react";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ResultsPage() {
  const { session } = useAppContext();
  const supabase = supabaseService.getClient();
  const navigate = useNavigate();

  const resultsQuery = useQuery({
    queryKey: [QUERY_KEY.scanResults],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*, Scan (*, Result (*))")
        .eq("user_uid", session.user.id)
        .order("created_at", { ascending: false });

      if (error) {
        throw error;
      } else {
        return data;
      }
    },
    enabled: !!session?.user.id,
  });

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const sections = useMemo(() => {
    if (resultsQuery.isSuccess) {
      const pastThirtyKey = "Past 30 days";
      const sectionsMap: any = {};
      const upsertSection = (key: string, value: any) => {
        if (sectionsMap[key]) {
          sectionsMap[key].push(value);
        } else {
          sectionsMap[key] = [value];
        }
      };

      resultsQuery.data.forEach((result) => {
        const locale = "en";
        const scanDate = DateTime.fromISO(
          result["Scan"]["Result"][0].created_at
        ).setLocale(locale);
        const localDate = DateTime.local().setLocale(locale);
        const diff = localDate.diff(scanDate, ["days"]);
        const diffInDays = Math.floor(diff.days);
        let dateText = scanDate.toFormat("DDD t");
        const dateMonth = scanDate.toFormat("LLLL");
        const dateYear = scanDate.get("year");
        const localYear = localDate.get("year");
        const sectionKey =
          diffInDays <= 30
            ? pastThirtyKey
            : dateYear === localYear
            ? dateMonth
            : scanDate.toFormat("LLLL y");

        switch (true) {
          case diffInDays === 0:
            dateText = `Today at ${scanDate.toFormat("t")}`;
            break;
          case diffInDays <= 30:
            dateText = `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
            break;
          default:
            break;
        }

        const hasAbnormalResults = checkHasAbnormalResults(
          result["Scan"]["Result"][0].color_data.predictions
        );
        const sectionData = {
          dateText,
          resultData: result,
          hasAbnormalResults,
        };

        upsertSection(sectionKey, sectionData);
      }); // foreach

      return Object.entries(sectionsMap).map(
        ([sectionLabel, sectionItems]) => ({
          sectionLabel,
          sectionItems: sectionItems as any[],
        })
      );
    } else {
      return [];
    }
  }, [resultsQuery.data, resultsQuery.isSuccess]);

  return (
    <>
      <OverrideTheme background="182 14% 96%" />
      <Header>
        <div className="mx-auto max-w-screen-sm px-6 py-5 grid grid-cols-header gap-4 items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={navigateBack}
            className="text-ribbon-cobalt-700"
          >
            <ChevronLeft size={30} />
          </Button>
          <div className="flex flex-col items-center justify-center">
            <div className="font-bold">Test History</div>
          </div>
          <Button
            asChild
            variant="ghost"
            size="icon"
            className="text-ribbon-cobalt-700"
          >
            <Link to="/">
              <House size={30} />
            </Link>
          </Button>
        </div>
      </Header>
      <div className="max-w-screen-sm w-full mx-auto px-5 pb-6">
        {resultsQuery.isLoading && (
          <div className="">
            <Spinner />
          </div>
        )}
        {resultsQuery.isSuccess && resultsQuery.data.length === 0 && (
          <div>No results found.</div>
        )}
        <div>
          {sections.map((section, index) => (
            <div key={"section-" + index}>
              <h3 className="mb-3 text-ribbon-stone-700">
                {section.sectionLabel}
              </h3>
              <div className="space-y-4">
                {section.sectionItems.map((item) => (
                  <ResultsHistoryCard
                    key={item.resultData.id}
                    id={item.resultData.id}
                    hasAbnormalResults={item.hasAbnormalResults}
                    dateText={item.dateText}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
