import { cn } from "@/lib/utils";
import { IIconProps } from "@/types/common";
import { COLORS } from "@/utils/colors";

export default function AddProfile({
  className,
  color = COLORS.cobalt["700"],
}: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <g clipPath="url(#clip0_3629_22314)">
        <path
          d="M4.09256 20.3327C5.02474 17.386 8.47684 15 12.834 15C17.1911 15 20.6432 17.386 21.5754 20.3327C21.6239 20.486 21.5931 20.6139 21.4801 20.7384C21.351 20.8806 21.1173 21 20.834 21H4.83398C4.55064 21 4.31701 20.8806 4.18789 20.7384C4.07491 20.6139 4.04405 20.486 4.09256 20.3327Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12.834"
          cy="7"
          r="4"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M21.834 10V14"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M23.834 12L19.834 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3629_22314">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.833984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
