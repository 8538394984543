import { cn } from "@/lib/utils";
import { EPredictionsParameterStatus } from "@/types/common";
import { getPredictionsParameterStatus } from "@/utils/common";
import { PREFIX } from "@/utils/variables";
import { useMemo } from "react";

interface IProps {
  value: number;
  label: string;
  predictionParameterName: string;
}

const barColors = {
  red: "#EE6365",
  green: "#99CB67",
  yellow: "#FA974C",
};

export default function PredictionRange({
  value,
  label,
  predictionParameterName,
}: IProps) {
  const max = Math.max(PREFIX[predictionParameterName].length - 1, 0);
  const parameterStatus = getPredictionsParameterStatus(
    predictionParameterName,
    value
  );

  let color =
    parameterStatus === EPredictionsParameterStatus.High
      ? barColors.red
      : parameterStatus === EPredictionsParameterStatus.Medium
      ? barColors.yellow
      : barColors.green;

  const c = 100 / (max + 1);
  const offset = c * value + c / 2;

  const fragments = useMemo(() => {
    const fragments = [];

    for (let i = 0; i <= max; i++) {
      const isActive = i === value;
      fragments.push(
        <div
          key={i}
          className="h-[3px] rounded bg-gray-200"
          style={{ background: isActive ? color : undefined }}
        />
      );
    }

    return fragments;
  }, [color, max, value]);

  return (
    <div className="w-[120px] pt-[30px]">
      <div className="w-full relative mb-[5px] h-[8px]">
        <div
          className={cn(
            "text-sm text-ribbon-stone-700 mb-1",
            "absolute bottom-[10px] -translate-x-2/4"
          )}
          style={{ left: offset + "%" }}
        >
          {label}
        </div>
        <div
          className={cn(
            "w-0 h-0 border-transparent border-l-[6px] border-r-[6px] border-t-[8px] border-t-black -translate-x-2/4",
            "absolute top-0"
          )}
          style={{ left: offset + "%" }}
        />
      </div>
      <div
        className="w-full grid"
        style={{ gridTemplateColumns: `repeat(${max + 1}, 1fr)`, gap: 3 }}
      >
        {fragments}
      </div>
    </div>
  );
}
