import { COLORS } from "@/utils/colors";
import "./styles.css";

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

export default function AppLogo2({
  color = COLORS.cobalt["700"],
  width = 180,
  height = 45,
}: IProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.63564 17.7621H0.0161585C0.0161585 17.6329 0 17.5036 0 17.3744V4.85336C0 2.38146 1.58352 0.458876 4.02344 0.0549714C4.6213 -0.0419657 5.26764 0.10344 5.88166 0.135752C6.01093 0.135752 6.15635 0.184221 6.28562 0.151908C9.29109 -0.575119 11.6825 1.41209 12.1188 3.88398C12.5228 6.14585 11.2463 8.3754 8.90329 9.08627C8.85481 9.08627 8.82249 9.08627 8.72554 9.13474C8.85481 9.24784 8.95176 9.32862 9.04871 9.4094C10.1798 10.3626 10.7777 11.5743 10.7938 13.0445C10.7938 14.9994 11.7148 16.3242 13.5246 17.0513C13.7508 17.1482 13.977 17.2451 14.2356 17.3421C11.3755 18.7638 8.28926 17.5036 7.41671 14.5794C7.28744 14.1432 7.22281 13.6746 7.20665 13.2223C7.15818 11.2835 6.2533 9.94255 4.44356 9.21552C4.24966 9.13474 4.05576 9.05396 3.86186 8.97318C3.86186 8.94087 3.86186 8.8924 3.86186 8.86009C4.4274 8.71468 4.99295 8.47234 5.55849 8.44003C6.64111 8.3754 7.33592 7.82609 7.8853 6.96981C9.09719 5.04723 8.69322 2.91461 7.31976 1.71906C6.17251 0.701218 6.07556 0.733531 4.896 1.73521C3.94265 2.54302 3.63564 3.67395 3.63564 4.90182C3.63564 9.0378 3.63564 13.1738 3.63564 17.3098C3.63564 17.4229 3.63564 17.5683 3.63564 17.7621Z"
        fill={color}
      />
      <path
        d="M22.1222 0.119672H25.7417V13.0446C25.7417 14.5148 26.2426 15.7265 27.4706 16.5828C28.1331 17.0513 28.2786 17.0513 28.9411 16.5828C30.1045 15.7912 30.67 14.6602 30.7023 13.287C30.7346 11.946 30.7346 10.6212 30.6539 9.28022C30.5731 7.63229 29.5228 6.37211 27.9231 5.83896C27.7292 5.77433 27.5353 5.72586 27.2767 5.62893C27.9554 5.24118 28.6341 5.01499 29.3612 4.99884C32.0596 4.93421 34.1764 6.90527 34.2733 9.6195C34.3218 10.9282 34.3218 12.2368 34.2572 13.5455C34.1118 16.4051 31.284 18.4246 28.4886 17.7461C28.1816 17.6653 27.81 17.7784 27.4706 17.8268C24.6268 18.1823 22.1384 16.1304 22.1061 13.2546C22.0576 8.94094 22.0899 4.61109 22.0899 0.29739C22.0899 0.248921 22.1061 0.200453 22.1222 0.103516V0.119672Z"
        fill={color}
      />
      <path
        d="M37.3264 0.120117H40.9459V13.0774C40.9459 14.4183 41.3822 15.5977 42.4809 16.4217C43.4666 17.1487 43.4343 17.1487 44.42 16.4217C45.4379 15.6462 45.9065 14.5476 45.9227 13.2874C45.9388 11.9949 45.955 10.6863 45.8904 9.39376C45.8096 7.66505 44.7593 6.40487 43.1273 5.87171C42.9495 5.80709 42.7556 5.75862 42.5133 5.67784C43.3535 5.20931 44.1776 4.98313 45.0825 5.06391C47.4093 5.29009 48.9605 6.67952 49.4614 8.95754C49.5099 9.21604 49.526 9.47454 49.526 9.73304C49.526 11.0094 49.5422 12.2857 49.5099 13.5621C49.4291 16.3571 46.5367 18.522 43.8383 17.8111C43.4181 17.698 43.0626 17.8596 42.6748 17.9081C40.1541 18.2473 37.795 16.4863 37.4234 13.9821C37.3749 13.659 37.3587 13.3359 37.3587 13.0127V0.588646C37.3264 0.443241 37.3264 0.297835 37.3264 0.120117Z"
        fill={color}
      />
      <path
        d="M64.6828 11.4934C64.6828 11.4934 64.6828 13.3998 64.5858 14.353C64.0526 16.8734 61.435 18.4082 58.9304 17.7943C58.6072 17.7135 58.2356 17.8266 57.8801 17.8751C55.3594 18.2143 52.9195 16.4533 52.6771 13.9491C52.5155 12.285 52.5317 10.5725 52.6771 8.90838C52.8548 6.93732 54.6646 5.30555 56.6521 5.11168C57.2176 5.04705 57.8155 5.1763 58.3972 5.1763C58.9789 5.1763 59.1081 5.14399 59.4636 5.09552C62.0005 4.7724 64.2627 6.42033 64.6666 8.95685C64.7959 9.78081 64.6828 10.6371 64.6828 11.4934C64.7313 11.4934 64.6505 11.4934 64.6828 11.4934ZM61.1441 11.5095C61.1279 11.5095 61.1603 11.5095 61.1441 11.5095C61.1441 10.8148 61.1926 10.1039 61.1279 9.40922C61.0148 7.92285 60.3039 6.79192 58.9627 6.11336C58.8173 6.04874 58.5911 6.03258 58.4456 6.11336C57.2015 6.69498 56.4097 7.68051 56.2966 9.00531C56.1673 10.6048 56.1835 12.2365 56.2804 13.8522C56.3612 15.2254 57.1691 16.2271 58.3972 16.8572C58.5588 16.938 58.8173 16.938 58.9789 16.8572C60.1423 16.2756 60.8694 15.3385 61.0633 14.0622C61.1926 13.2221 61.1118 12.3658 61.1441 11.5257V11.5095Z"
        fill={color}
      />
      <path
        d="M80 17.7618H76.3967V17.3095C76.3967 14.7406 76.429 12.1557 76.3805 9.58682C76.3482 8.05198 75.6372 6.87258 74.2476 6.1294C74.0214 6.00015 73.8113 6.00015 73.5851 6.1294C72.147 6.9049 71.4522 8.13277 71.436 9.73223C71.4037 12.2687 71.4199 14.8053 71.4199 17.3418V17.7618H67.8166C67.8166 17.6649 67.8004 17.5841 67.8004 17.5033C67.8004 14.9345 67.7842 12.3495 67.8004 9.7807C67.8166 7.64808 69.1739 5.82243 71.1613 5.24081C71.9531 5.01462 72.7449 4.99847 73.5689 5.16003C73.9244 5.22465 74.3284 5.16003 74.7 5.09541C77.043 4.78844 79.289 6.32327 79.8223 8.61745C79.9192 9.05367 79.9677 9.50604 79.9838 9.94226C80 12.398 79.9838 14.8699 79.9838 17.3256C80 17.4549 80 17.6003 80 17.7618Z"
        fill={color}
      />
      <path
        d="M15.4313 4.8208C17.3218 5.22471 18.7922 6.87263 18.9861 8.77906C19.0184 9.1345 19.0346 9.50609 19.0346 9.86153V17.7457H15.4313V4.8208Z"
        fill={color}
      />
      <path
        d="M15.4313 0.103516H19.0184V3.57709H15.4313V0.103516Z"
        fill={color}
      />
    </svg>
  );
}
