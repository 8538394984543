/* eslint-disable jsx-a11y/alt-text */
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AnalyzeAnimation from "./AnalyzeAnimation";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTestContext } from "@/contexts/testContext";
import { DateTime } from "luxon";
import TakePhotoButton from "./TakePhotoButton";
import { Drawer, DrawerContent, DrawerTitle } from "@/components/ui/drawer";
import PhotoCorrectionAnimation from "@/assets/mp4/Photo_Correction_Animation.mp4";
import StripMissingAnimation from "@/assets/mp4/Strip_Missing_Animation.mp4";
import { LAST_TEST_DATETIME_STORAGE_KEY } from "@/utils/variables";

function AnalyzeStrip() {
  const location = useLocation();
  const navigate = useNavigate();
  const stripPhoto = location.state?.stripPhoto;
  const {
    testResultData,
    stripPhoto: contextStripPhoto,
    error,
    analyzeStripPhoto,
    resetContext,
  } = useTestContext();
  const [errorState, setErrorState] = useState<any>(undefined);

  const goToResult = useCallback(() => {
    if (testResultData) {
      localStorage.setItem(
        LAST_TEST_DATETIME_STORAGE_KEY,
        DateTime.now().toISO()
      );
      resetContext();
      navigate("/test/result", {
        state: {
          resultData: testResultData,
          stripPhoto: contextStripPhoto || stripPhoto,
          resultDate: DateTime.local().toISO(),
        },
        replace: true,
      });
    }
  }, [contextStripPhoto, navigate, stripPhoto, testResultData, resetContext]);

  const handleRetakePhoto = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        analyzeStripPhoto(file);
      }
    },
    [analyzeStripPhoto]
  );

  const errorProps = useMemo(() => {
    if (!errorState) return undefined;

    switch (true) {
      case errorState.response?.data?.error === "StripNotFoundError":
        return {
          animation: StripMissingAnimation,
          title: "The test strip is missing",
          description:
            "Make sure the test strip is placed in the black area of the Color Card.",
        };
      default:
        return {
          animation: PhotoCorrectionAnimation,
          title: "Sorry, we’re unable to analyze your photo",
          description:
            "Make sure the entire Color Card is visible, in-focus and in good lighting.",
        };
    }
  }, [errorState]);

  const handleDrawerAnimationEnd = useCallback(() => {
    if (!error) {
      setErrorState(undefined);
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      if (!contextStripPhoto) {
        navigate("/test");
      }
    }, 500);
  }, [contextStripPhoto, navigate]);

  useEffect(() => {
    if (error) {
      setErrorState(error);
    }
  }, [error]);

  if (!stripPhoto) {
    return <Navigate to="/test" replace />;
  }

  return (
    <div className="w-full max-w-screen-sm mx-auto px-5 py-8">
      <StripPhotoImage
        photoFile={contextStripPhoto || stripPhoto}
        className="rounded-lg max-h-[70vh] mx-auto mb-8"
      />
      <h2 className="text-center font-bold text-2xl mb-5">
        Analyzing test strip...
      </h2>
      <AnalyzeAnimation
        scanResult={testResultData}
        colorsCount={14}
        onAnimationIdle={goToResult}
      />
      <Drawer open={!!error} modal={true} dismissible={false}>
        <DrawerContent onAnimationEnd={handleDrawerAnimationEnd}>
          {errorProps && (
            <div className="w-full max-w-[380px] mx-auto px-5 py-5">
              <div className="mb-7">
                <video
                  className="w-full h-100 rounded-lg"
                  playsInline
                  muted
                  autoPlay
                  preload="auto"
                  loop
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <source src={errorProps.animation} type="video/mp4" />
                </video>
              </div>
              <DrawerTitle asChild className="text-2xl">
                <h2 className="text-center font-semibold mb-5">
                  {errorProps.title}
                </h2>
              </DrawerTitle>
              <p className="mb-6 text-center">{errorProps.description}</p>
              <TakePhotoButton
                variant="outline"
                onChangePicture={handleRetakePhoto}
                className="w-full"
              >
                Retake Photo
              </TakePhotoButton>
            </div>
          )}
        </DrawerContent>
      </Drawer>
    </div>
  );
}

const StripPhotoImage = memo(function ({
  photoFile,
  className,
}: {
  photoFile: any;
  className?: string;
}) {
  if (!(photoFile instanceof File)) {
    return null;
  } else {
    return (
      <img
        src={URL.createObjectURL(photoFile)}
        alt="Strip"
        className={className}
      />
    );
  }
});

export default AnalyzeStrip;
