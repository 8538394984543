import { IIconProps } from "@/types/common";
import { COLORS } from "@/utils/colors";

export default function Kidneys({
  className,
  color = COLORS.cobalt["700"],
}: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0909 4.08031C18.5455 1.52668 16 4.08041 16 5.78281C16 7.4852 17.697 7.4852 17.697 9.18762C17.697 10.89 16 10.89 16 12.5925C16 14.2949 18.5455 15.9972 21.0909 14.2948C23.6364 12.5924 23.6364 6.63394 21.0909 4.08031Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M2.90909 4.08031C5.45455 1.52668 8 4.08041 8 5.78281C8 7.4852 6.30303 7.4852 6.30303 9.18762C6.30303 10.89 8 10.89 8 12.5925C8 14.2949 5.45455 15.9972 2.90909 14.2948C0.363636 12.5924 0.363636 6.63394 2.90909 4.08031Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M6.5 9H8.5C9.60457 9 10.5 9.89543 10.5 11V21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17.5 9H15.5C14.3954 9 13.5 9.89543 13.5 11V21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
