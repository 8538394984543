import { ReactNode, useEffect, useState } from "react";
import { Toaster } from "./ui/sonner";
import { isFirefox } from "react-device-detect";
import FirefoxNotSupportedModal from "./FirefoxNotSupportedModal";

interface IProps {
  children?: ReactNode;
}

export default function AppLayout({ children }: IProps) {
  const [openFirefoxNotSupportedModal, setOpenFirefoxNotSupportedModal] =
    useState(false);

  useEffect(() => {
    if (isFirefox) {
      setOpenFirefoxNotSupportedModal(true);
    }
  }, []);

  return (
    <div className="grow flex flex-col">
      <div id="app-main" className="grow flex flex-col">
        {children}
      </div>
      <Toaster
        theme="light"
        richColors
        position="top-right"
        visibleToasts={5}
        toastOptions={{
          unstyled: false,
          className: "!text-base",
          classNames: {
            success: "!bg-ribbon-sky-300 !text-ribbon-cobalt-700",
            info: "!bg-ribbon-stone-300 !text-ribbon-cobalt-700",
          },
        }}
      />
      <FirefoxNotSupportedModal
        open={openFirefoxNotSupportedModal}
        onOpenChange={() => {}}
      />
    </div>
  );
}
