import { cn } from "@/lib/utils";
import { IIconProps } from "@/types/common";
import { COLORS } from "@/utils/colors";

export default function Email({
  className,
  color = COLORS.cobalt["700"],
}: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <rect
        x="3.16699"
        y="6"
        width="18"
        height="12"
        rx="1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.16699 7L11.567 12.55C11.9225 12.8167 12.4114 12.8167 12.767 12.55L20.167 7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.16699 17L9.16699 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.167 12L20.167 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
