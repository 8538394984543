import { LucideProps } from "lucide-react";
import { ReactNode } from "react";
import { DropdownMenuItem } from "./ui/dropdown-menu";

export default function CustomDropdownMenuItem({
  children,
  link,
  Icon,
  onClick = () => {},
}: {
  children: ReactNode;
  link?: string;
  Icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref">>;
  onClick?: () => void;
}) {
  const content = (
    <>
      <span className="p-3 rounded-full bg-ribbon-cobalt-700 text-white">
        {Icon && <Icon size={18} />}
      </span>
      <span>{children}</span>
    </>
  );

  return (
    <DropdownMenuItem
      asChild={!!link}
      className="cursor-pointer flex items-center space-x-4 rounded-2xl"
      onClick={onClick}
    >
      {!!link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      ) : (
        content
      )}
    </DropdownMenuItem>
  );
}
