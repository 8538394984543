import { COLORS } from "@/utils/colors";
import { EResultStatusLabel } from "@/utils/variables";

interface IProps {
  isAbnormal?: boolean;
  normalLabel?: EResultStatusLabel;
  abnormalLabel?: EResultStatusLabel;
}

function ResultStatusBadge({
  isAbnormal = false,
  normalLabel = EResultStatusLabel.Normal,
  abnormalLabel = EResultStatusLabel.Abnormal,
}: IProps) {
  const bageBg = isAbnormal ? COLORS.rose["300"] : COLORS.stone["300"];
  const bageFg = isAbnormal ? COLORS.rose["700"] : COLORS.stone["900"];
  const bageLabel = isAbnormal ? abnormalLabel : normalLabel;

  return (
    <div
      className="px-3 py-1 rounded-md font-normal"
      style={{ background: bageBg, color: bageFg }}
    >
      {bageLabel}
    </div>
  );
}

export default ResultStatusBadge;
