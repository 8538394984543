import { ReactNode, useEffect } from "react";
import { preloadVideo, preloadImage } from "@remotion/preload";
import { TEST_INSTRUCTIONS_STEPS } from "@/utils/scanUtils";
import { ScanMediaType } from "@/types/Scan";
import TestPackagePhoto from "@/assets/img/photo_checkup_package.png";

interface IProps {
  children: ReactNode;
}

export default function PreloadScanAssets({ children }: IProps) {
  useEffect(() => {
    const images = TEST_INSTRUCTIONS_STEPS.map((s) => s.media).filter(
      (m) => m?.type === ScanMediaType.Image
    );
    const videos = TEST_INSTRUCTIONS_STEPS.map((s) => s.media).filter(
      (m) => m?.type === ScanMediaType.Video
    );
    images.forEach((i) => i && preloadImage(i?.src));
    videos.forEach((i) => i && preloadVideo(i?.src));
    preloadImage(TestPackagePhoto);
  }, []);

  return children;
}
